/**
  * @tokens Config
*/

$spacing-grid-limit: 1320px !default;
$spacing-grid-gap: var(--spacing_sm_400) !default;
$spacing-grid-max-width: calc(#{$spacing-grid-limit} + #{$spacing-grid-gap} + #{$spacing-grid-gap});

// responsive breakpoints, cannot use CSS vars in media query parameters
// USE MOBILE-FIRST BREAKPOINTS! (min-width, not max-width)
$grid-breakpoints: (
  mobile: 360px,
  tablet: 768px,
  desktop: 1024px,
  wide: 1440px,
  ultra: 1600px
) !default;

/* Leftovers, need to find a way to get rid of these, or implement CSS vars */
$orange: #e97722 !default; // @presenter Color
$orange2: #ff9731 !default; // @presenter Color
$clockwork: #ff9731 !default; // @presenter Color
$divider-color-gray: var(--colors_global_divider) !default; // @presenter Color
$active-light-blue: #d9eef5 !default; // @presenter Color
$active-medium-blue: #42aace !default; // @presenter Color

$black: var(--colors_mono_black, #000); // @presenter Color
$white: var(--colors_mono_white, #fff); // @presenter Color

$admin-nav-color: #97d9f0; // @presenter Color

// TODO: Move to component file
$icon-width-md: 16px !default; // @presenter Spacing
$icon-height-md: 16px !default; // @presenter Spacing
$icon-width-lg: 24px !default; // @presenter Spacing
$icon-height-lg: 24px !default; // @presenter Spacing

$transition-duration: 0.3s !default;
$transition-speed: ease !default;
