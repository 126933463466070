@use "sass:math"; @import "@client/scss/__config.scss"; @import "@theme/scss/_config.scss"; @import "@core/plugins/scss/_mixins.scss";
.data-table-display {
  .filter-controls {
    margin-bottom: 40px;
    .filter-section {
      width: 900px;
      margin: 0 auto 40px;
      display: flex;
      .filter-categories {
        border-right: 1px solid #222428;
        max-width: 200px;
        width: 200px;
        padding: 15px;
        .filter-option {
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 18px;
          margin-bottom: 20px;
          color: #231e33;
          display: flex;
          label {
            margin-left: 8px;
            margin-bottom: 0;
          }
        }
      }
    }

    .clear-filters {
      margin: 0 auto;
      cursor: pointer;
      display: block;
      text-align: center;
    }
  }
  .search-filters {
    display: flex;
    align-items: center;
    justify-content: space-between;

    input[type="text"] {
      width: calc(100% / 3);
      padding: 0.5rem;
    }

    .control-buttons {
      display: flex;
      justify-content: flex-end;
      width: calc(2 * 100% / 3);
      .secondary {
        margin-left: 1rem;
      }
    }

    .filters:not(:last-child) {
      margin-right: 0.5rem;
    }
  }

  .sort-controls {
    margin: 52px 0 0 0;
    height: 28px;
    button {
      padding: 0 3px;
      &.selected {
        color: var(--colors_global_primary_mono);
      }
    }
    display: flex;
    justify-content: space-between;

    .order-controls {
      display: flex;
      flex-direction: row;

      button {
        margin-right: 10px;
        border: none;
        background: transparent;
      }
      .filter-count {
        background-color: var(--button_primary_background-color_default);
        color: #fff;
        width: 20px;
        height: 20px;
        margin: 0 7px 2px 20px;
        display: inline-block;
        line-height: 20px;
        vertical-align: middle;
        text-align: center;
        border-radius: 4px;
      }
    }

    .page-results {
      margin-left: auto;
    }
  }

  .sort-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      padding: 0 3px;
      border: none;
      background: transparent;
      &.selected {
        color: var(--colors_global_primary_mono);
      }
    }
    .order-controls {
      display: flex;
    }

    .page-results {
      display: flex;
      align-items: center;
      border: none;
      background: transparent;
      .results {
        margin-right: 1rem;
        font-size: 0.8rem;
        color: #666;
      }
      button {
        border: none;
        background: transparent;
      }
    }
  }

  .pagination {
    margin-top: 48px;
    span {
      height: 40px;
      width: 40px;
      line-height: 40px;
      text-align: center;
      &.active {
        background: var(--button_primary_background-color_default);
        border-radius: 10px;
        color: white;
      }
    }
  }

  .data-table-empty {
    text-align: center;
    .data-table-landing-msg {
      max-width: 680px;
      margin: 148px auto 120px auto;
      h1 {
        font-style: normal;
        font-weight: 300;
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 32px;
      }
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        margin-bottom: 0px;
      }
    }
    .data-table-add-button {
      background-color: white;
      border-radius: 4px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      margin: 0px auto 180px auto;
      width: 328px;
      height: 264px;
      padding: 48px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-weight: 300;
      font-size: 24px;
      line-height: 40px;
      div {
        font-size: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-family: monospace;
        width: 96px;
        height: 96px;
        margin-bottom: 32px;
        border-radius: 100rem;
        background: #86919c;
      }
    }
  }
  .data-table-list {
    b .data-table-list-add-row-button {
      width: 100%;
      height: 48px;
      left: 348px;
      top: 168px;
      border: none;
      background: #ffffff;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;

      &.edit-rows {
        margin-left: 2%;
        width: 98%;
      }
      .icon {
        color: $active-medium-blue;
        width: 24px;
        height: 24px;
        margin-right: 14px;
      }
    }
  }

  .registries-table {
    // margin: 32px 48px;
    width: 100%;
    border-radius: 5px;
    overflow: scroll;
    .hidden-column {
      visibility: hidden;
      border: none;
    }
    .data-table {
      tbody {
        tr {
          max-height: 116px;
        }
      }
    }
  }
  table {
    border-collapse: collapse;
    overflow-x: scroll;

    thead {
      background: var(--colors_global_primary_mono);
    }

    th {
      resize: both;
    }

    th,
    td {
      padding: 8px;
      text-align: left;
      background-color: var(--global_background_color);
      border: 1px solid #e0e0e0;
    }

    td {
      height: auto;
      overflow-y: visible;
      white-space: normal;
      word-break: break-all;
    }

    .link {
      display: flex;
      cursor: pointer;
      align-items: center;

      .text {
        flex: 1;
      }

      .icon {
        margin-left: 10px;
      }
    }

    .first-column {
      position: sticky;
      left: 0;
      z-index: 1;
      filter: drop-shadow(2px 0px 4px rgba(0, 0, 0, 0.1));
    }

    th {
      font-family: "Elevance Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #ffffff;
      .first-column {
        position: sticky;
        left: 0;
        z-index: 1;
        background-color: white;
      }
      background: var(--button_primary_background-color_default);
      // target the first column
      &:first-child {
        border-radius: 4px 0px 0px 0px;
      }
      // target the last column
      &:last-child {
        border-radius: 0px 4px 0px 0px;
      }
    }

    @media (max-width: 768px) {
      th,
      td {
        display: block;
      }

      th {
        position: absolute;
        top: -9999px;
        left: -9999px;
      }

      td {
        margin-bottom: 10px;

        &:before {
          content: attr(name);
          font-weight: bold;
          width: 40%;
        }
      }
    }
  }
}
