@use "sass:math"; @import "@client/scss/__config.scss"; @import "@theme/scss/_config.scss"; @import "@core/plugins/scss/_mixins.scss";
.showcase-carousel-global {
  position: relative;
  width: 100%;
  .slides {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    &::-webkit-scrollbar {
      display: none;
    }
    cursor: grab;
    .slide {
      display: inline-block;
      display: table-cell;
      vertical-align: middle;

      .image {
        cursor: pointer;
      }
      a {
        white-space: normal;
      }
      .title {
        white-space: normal;
      }
    }
  }

  // Global Link styles (from Theme)
  a {
    color: var(--global_link_color);
    text-decoration: var(--global_link_decoration);
    font-size: var(--global_text_size);
    line-height: var(--global_text_lineheight);
    &:hover {
      color: var(--global_link_hover_color);
      text-decoration: var(--global_link_hover_decoration);
    }
  }

  // Global headings
  h1 {
    color: var(--global_h1_color);
    font-family: var(--global_h1_font);
    font-size: var(--global_h1_size);
    font-style: var(--global_h1_font_style);
    font-weight: var(--global_h1_weight);
    letter-spacing: var(--global_h1_letter_spacing);
    line-height: var(--global_h1_lineheight);
    margin: 0 0 var(--global_h1_margin) 0;
    text-transform: var(--global_h1_text_transform);
  }

  h2 {
    color: var(--global_h2_color);
    font-family: var(--global_h2_font);
    font-size: var(--global_h2_size);
    font-style: var(--global_h2_font_style);
    font-weight: var(--global_h2_weight);
    letter-spacing: var(--global_h2_letter_spacing);
    line-height: var(--global_h2_lineheight);
    margin: 0 0 var(--global_h2_margin) 0;
    text-transform: var(--global_h2_text_transform);
  }

  h3 {
    color: var(--global_h3_color);
    font-family: var(--global_h3_font);
    font-size: var(--global_h3_size);
    font-style: var(--global_h3_font_style);
    font-weight: var(--global_h3_weight);
    letter-spacing: var(--global_h3_letter_spacing);
    line-height: var(--global_h3_lineheight);
    margin: 0 0 var(--global_h3_margin) 0;
    text-transform: var(--global_h3_text_transform);
  }

  h4 {
    color: var(--global_h4_color);
    font-family: var(--global_h4_font);
    font-size: var(--global_h4_size);
    font-style: var(--global_h4_font_style);
    font-weight: var(--global_h4_weight);
    letter-spacing: var(--global_h4_letter_spacing);
    line-height: var(--global_h4_lineheight);
    margin: 0 0 var(--global_h4_margin) 0;
    text-transform: var(--global_h4_text_transform);
  }

  h5 {
    color: var(--global_h5_color);
    font-family: var(--global_h5_font);
    font-size: var(--global_h5_size);
    font-style: var(--global_h5_font_style);
    font-weight: var(--global_h5_weight);
    letter-spacing: var(--global_h5_letter_spacing);
    line-height: var(--global_h5_lineheight);
    margin: 0 0 var(--global_h5_margin) 0;
    text-transform: var(--global_h5_text_transform);
  }

  h6 {
    color: var(--global_h6_color);
    font-family: var(--global_h6_font);
    font-size: var(--global_h6_size);
    font-style: var(--global_h6_font_style);
    font-weight: var(--global_h6_weight);
    letter-spacing: var(--global_h6_letter_spacing);
    line-height: var(--global_h6_lineheight);
    margin: 0 0 var(--global_h6_margin) 0;
    text-transform: var(--global_h6_text_transform);
  }
}
