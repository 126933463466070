@use "sass:math"; @import "@client/scss/__config.scss"; @import "@theme/scss/_config.scss"; @import "@core/plugins/scss/_mixins.scss";
$color-2: #215ca0;
$color-3: #00a1e0;
$color-5: #032e61;
$color-9: #c2e8ff;

html.admin {
  #homepage-carousel-container {
    margin-top: 0;
  }
}

.circle-wrapper {
  position: absolute;
  bottom: 75px;
  z-index: 2;
  width: 445px;
  height: 445px;

  @media(max-width: 1170px) {
    width: 370px;
    height: 370px;
    left: 50px;
    bottom: 145px;
  }

  @media(max-width: 1023px) {
    left: 0;
    bottom: 50px;
  }

  @media(max-width: 480px) {
    width: 280px;
    height: 280px;
    left: calc(50% - 140px);
  }
}

.main-circle {
  width: 445px;
  height: 445px;

  @media(max-width: 1170px) {
    width: 370px;
    height: 370px;
  }

  @media(max-width: 480px) {
    width: 280px;
    height: 280px;
  }
}

.main-circle-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #fff;
  z-index: 1;
  box-shadow: 0 0 30px rgba(0,0,0,0.24),
}

.main-circle {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
}

.main-circle {
  z-index: 1;

  &.current {
    z-index: 2;
  }
}

.child-circle-large,
.child-circle-medium,
.child-circle-small {
  position: absolute;
  z-index: 0;
  border-radius: 50%;
  overflow: hidden;
  background-color: #dedede;
}

.child-circle-large {
  top: calc(50% - 34%);
  left: calc(50% - 34%);
  width: 56%;
  height: 56%;
}

.child-circle-medium {
  top: calc(50% - 20%);
  left: calc(50% - 20%);
  width: 33%;
  height: 33%;
}

.child-circle-small {
  top: calc(50% - 10%);
  left: calc(50% - 10%);
  width: 21%;
  height: 21%;
}

.overline-container {
  display: flex;
  flex-direction: row;
}

.no-bg {
  background-color: transparent;
}

#homepage-carousel-container {
  position: relative;
  background-color: #fff;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    right: calc(50% - 1880px);
    bottom: 40px;
    z-index: 1;
    display: block;
    width: 4800px;
    height: 4800px;
    background-color: #e4f7ff;
    border-radius: 50%;

    @media(max-width: 1170px) {
      bottom: 100px;
    }

    @media(max-width: 1023px) {
      bottom: 210px;
    }
  }

  .overline {
    position: relative;
    width: 400px;
    color: $color-2;
    font-size: 14px;
    font-weight: bold;
    font-family: var(--font_5);
    margin-bottom: 12px;
    text-transform: uppercase;
    margin-left: 10px;
    margin-right: 10px;
    padding: 12px 0 11px 20px;
    border-left: 1px solid #8cd3f8;
    border-right: 1px solid #8cd3f8;
    overflow: hidden;

    span {
      display: inline-block;
      line-height: 16px;
      margin-right: 17px;
      letter-spacing: 0.6px;

      &.active {
        border-bottom: 2px solid $color-3;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  .overline-wrapper {
    width: 1200px;
  }

  .overline-single {
    color: $color-2;
    font-size: 14px;
    font-weight: bold;
    font-family: var(--font_5);
    margin-bottom: 12px;
    text-transform: uppercase;
    letter-spacing: 0.6px;

    span {
      opacity: 1;
      transition: opacity 0.3s;

      &.fade {
        opacity: 0;
      }
    }
  }

  .animation-button {
    width: 32px;
    height: 32px;
    background: #f4faff;
    border: 1px solid $color-9;
    border-radius: 50%;
    padding: 0;
    margin-top: 10px;
    vertical-align: middle;

    .icon {
      display: block;
      width: 20px;
      height: 20px;
    }

    @media(max-width: 1023px) {
      margin-top: 0;

      &.next {
        margin-left: 10px;
        float: right;
      }

      &.back {
        float: left;
        margin-right: 10px;
      }

      &.next,
      &.back {
        margin-top: 10px;
      }
    }

    &:hover {
      border-color: #8cd3f8;
    }

    &.back {
      transform: rotate(180deg);
    }
  }

  .cta-button {
    border: var(--button_primary_border_size) solid var(--button_primary_border_color);
    background: linear-gradient(to right, var(--button_primary_bg_color), var(--button_primary_bg_color_end));
    line-height: var(--button_primary_text_line_height);
    text-decoration: none;
    &:hover {
      background: linear-gradient(to right, var(--button_primary_bg_hover_color), var(--button_primary_bg_hover_color_end));
    }
    @media(max-width: 1023px) {
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      display: inline-block;
      width: 70%;
    }

    @media(max-width: 480px) {
      width: calc(100% - 100px);
    }
  }

  .mobile-nav {
    @media(max-width: 1023px) {
      position: relative;
      height: 75px;
      text-align: center;
      border-bottom: 0;
      margin-bottom: 55px;
    }
  }

  h1 {
    color: $color-5;
    font-family: var(--font_4);
    font-size: 56px;
    font-weight: 700;
    padding-right: 34px;
    margin-bottom: 40px;
    line-height: 60px;

    @media(max-width: 1023px) {
      font-size: 46px;
      line-height: 52px;
      padding-right: 46px;
      height: 130px;

    }

    @media(max-width: 600px) {
      font-size: 35px;
      line-height: 38px;
      padding-right: 0;
      height: 90px;
    }
  }

  .carousel-container {
    width: 1170px;
    margin: 0 auto;
    padding: 0 32px;

    @media (max-width: 1170px) {
      width: 100%;
    }
  }

  .carousel-navigation {
    width: 100%;
    margin-top: 0;
    padding: 0;

    li {
      position: relative;
      list-style-type: none;

      &:hover {
        &:after {
          right: -10px;
        }
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        bottom: 28px;
        width: 38px;
        height: 38px;
        background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAV9JREFUaAXtmD1uwkAQhWeXa6TzaegokFIkhC5dTkNHRyBFpBRciC7XYBc/LMuADJY1P0sxK9my1+v1+57HmlkTeXMH3AF3wB1wB9yBcg4E7qOrj800J1pjnkDx67Bb7Llzjrk/jhncNxbiM+UXbInSXw302jdOq48NcC0sT1KiH0sINgDCpg6eYwdiC8EGQMzHSG+lINgfces8wgbhQ5QnbR+gAHf4Xv52fbJHYgCQVQJCFKAEhDiANYQKgCWEGoAVhCqABYQ6wEMIinNu7cROZBA41JAH+pJdprQaunfougnAkAjOdROAewmuqaM48onUAe6JP5cYAmsH1Y/4oXih+kgNwEI8gk8FwEq8CoCleHEAa/GiACXEiwGUEi8CUL1vZ/idYr2UhHg0diJr6hnbdXAjvdmzAS4ns1jEXz9P4A2ESJ+Bwj+2iPJYKMPeCvVzd8AdcAfcAXfAHXhGB04Dj942XYBxEQAAAABJRU5ErkJggg==') no-repeat 0 0;
      }
    }

    a {
      position: relative;
      display: inline-block;
      background: none;
      border: 0;
      color: #2359a0;
      font-size: 20px;
      text-decoration: none;
      padding-right: 0;
      padding-left: 0;
      font-weight: 400;
      padding-top: 0;
      padding-bottom: 24px;
      margin-right: 56px;
      border-bottom: 3px solid transparent;

      &:hover {
        text-decoration: none;

        &:after {
          content: "";
          display: block;
          position: absolute;
          bottom: 16px;
          width: 100%;
          height: 3px;
          background-color: $color-3;
        }

        span {
          visibility: visible;
        }
      }

      span {
        line-height: 1.5em;
        visibility: hidden;
      }
    }

    span {
      display: block;
      margin: 0;
      font-size: 14px;
      text-transform: uppercase;
      text-align: left;
      color: #2359a0;
    }
  }

  .left-content {
    position: relative;
    z-index: 2;
    float: left;
    width: 50%;
    padding-top: 110px;

    @media(max-width: 1170px) {
      padding-top: 80px;
    }

    @media(max-width: 1023px) {
      position: relative;
      z-index: 10;
      width: 704px;
      padding-top: 42px;
      padding-right: 15px;
      padding-left: 15px;
      margin: 0 auto;
      float: none;
    }

    @media(max-width: 767px) {
      width: 100%;
      padding-top: 19px;
      padding-right: 0;
      padding-left: 0;
    }
  }

  .right-content-animation {
    position: relative;
    float: left;
    width: 50%;
    height: 650px;

    @media(max-width: 1023px) {
      float: none;
      width: 370px;
      height: 450px;
      margin: 0 auto;
    }

    @media(max-width: 480px) {
      width: 100%;
      height: 370px;
    }
  }

  .mobile-circle-nav {
    @media (max-width: 1023px) {
      width: 300px;

      a {
        position: relative;
        font-size: 20px;
        color: #222;
        border-bottom: 3px solid $color-3;
        padding-bottom: 7px;

        &.origin {
          font-size: 14px;
          color: rgba(0, 92, 165, 0.64);
          border-bottom: 0;

          &:after {
            display: none;
          }
        }

        &:after {
          content: '';
          display: block;
          position: absolute;
          right: -50px;
          bottom: 6px;
          width: 38px;
          height: 38px;
          background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAV9JREFUaAXtmD1uwkAQhWeXa6TzaegokFIkhC5dTkNHRyBFpBRciC7XYBc/LMuADJY1P0sxK9my1+v1+57HmlkTeXMH3AF3wB1wB9yBcg4E7qOrj800J1pjnkDx67Bb7Llzjrk/jhncNxbiM+UXbInSXw302jdOq48NcC0sT1KiH0sINgDCpg6eYwdiC8EGQMzHSG+lINgfces8wgbhQ5QnbR+gAHf4Xv52fbJHYgCQVQJCFKAEhDiANYQKgCWEGoAVhCqABYQ6wEMIinNu7cROZBA41JAH+pJdprQaunfougnAkAjOdROAewmuqaM48onUAe6JP5cYAmsH1Y/4oXih+kgNwEI8gk8FwEq8CoCleHEAa/GiACXEiwGUEi8CUL1vZ/idYr2UhHg0diJr6hnbdXAjvdmzAS4ns1jEXz9P4A2ESJ+Bwj+2iPJYKMPeCvVzd8AdcAfcAXfAHXhGB04Dj942XYBxEQAAAABJRU5ErkJggg==') no-repeat 0 0;
        }
      }
    }

    @media(max-width: 600px) {
      a {
        font-size: 16px;
        padding-bottom: 3px;

        &:after {
          width: 32px;
          height: 25px;
          bottom: 2px;
          background-size: cover;
        }
      }
    }

    @media(max-width: 414px) {
      width: 220px;
    }
  }
}
