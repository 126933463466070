@use "sass:math"; @import "@client/scss/__config.scss"; @import "@theme/scss/_config.scss"; @import "@core/plugins/scss/_mixins.scss";
.loading-block {
  text-align: center;
  margin: 24px;
}

.spinner-border {
  display: inline-block;
  width: 20px;
  height: 20px;
  vertical-align: -0.125em;
  border: 3px solid;
  border-right: 3px solid transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite;

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    border: 0;
  }
}

@keyframes spinner-border {
  to {
    transform: rotate(1turn);
  }
}
