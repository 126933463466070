@use "sass:math"; @import "@client/scss/__config.scss"; @import "@theme/scss/_config.scss"; @import "@core/plugins/scss/_mixins.scss";
.mfa-error {
  color: #dc3545;
  font-size: 16px;
  display: block;
  padding-top: 4px;
}
.mfa-input {
  label {
    font-size: 16px !important;
  }
}

.mfa-input > label {
  font-size: 16px !important;
}
