/*=====     Grid Defaults     =====*/

// column measurements
$grid-column-width: 70px; // 5rem = exactly 960px at desktop breakpoint
$grid-type: magic; // static, fluid or magic
$grid-gutter: 24px; // value or false
$grid-padding: false; // value or false

// Mosaic layout variables
$mosaic-gutter: $grid-gutter;

// responsive breakpoints
$grid-breakpoints: (
  mobile: 360px,
  tablet: 640px,
  desktop: 960px,
);

$main-sidebar-width: 296px;
$main-sidebar-width-2xl: 360px;

// admin bar top spacing
$admin-bar-height: 48px;

/*=====     Global Defaults     =====*/

// Primary colors
$clockwork: #ff9731; // primary button
$oompa-loompa: #e87722; // primary button hover
$midnight: #0e1d2b; // admin header

$action-blue: #42aace;
$blue-hover: #b3ddeb; // primary + blue button
$blue-background: #d9eef5; // primary + blue button

$admin-nav-color: #97d9f0; // admin nav color

$green: #3cc78e;
$green-hover: #c4eedc;
$green-background: #ebf9f3;

$red: #dc3545;
$red-hover: #f4c2c7;
$red-background: #fbeaec;

// Grayscale Palette
$slate-1: #222428; // text only
$slate-2: #717e8b; // text only (disabled text color)
$slate-3: #aab2b9; // button hover
$slate-4: #d3d7db; // button backgrounds, form-field backgrounds
$slate-5: #e1e4e7; // dividers
$slate-6: #eff1f2; // disabled button, element background
$slate-background: #f8f9f9; // CMS background
$white: #ffffff;

// Accent colors
$indigo: #2d536e;
$steel: #4f758b;
$gandalf: #75a3b3;
$iceman: #a4bcc2;

/* ~~~~~~~~~~ */
// to be removed
$orange: $clockwork;
$orange2: $oompa-loompa;
$orange3: #e87722;
$text: $slate-1;
$active-medium-blue: $action-blue;
$action-blue-hover: $blue-hover;
$active-light-blue: $blue-background;
$admin-icon-grey: $slate-1;
$gray-1: $slate-2;
$gray-2: $slate-4;
$gray-3: #7b8996;
$gray-4: #1a1a1a;
$gray-5: #e0e0e0;
$gray-6: #3c3c3c;
$black: #000000;
$gray-7: #979797;
$gray-8: $slate-3;
$delete-button-color: #dc3545;
$warning-background: #fcedef;
$error_color: #c00;
$background-color-gray-02: #eff1f2;
$alert_color: $clockwork;
$border-color: #e1e4e7;
$admin-header-color: $midnight;
$background-color-gray: #f6f6f6;
$success_color: #28a745;
$divider-color-gray: #d8d8d8;
$inactive-color-gray: #b3b3b3;
$admin-footer-color: #2b2b2b;
$border-hover-color: #56bffb;
$boring-beige: #f8f9f9;
