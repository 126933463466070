@use "sass:math"; @import "@client/scss/__config.scss"; @import "@theme/scss/_config.scss"; @import "@core/plugins/scss/_mixins.scss";
.password-error {
  width: 100%;

  .error-block {
    background-color: white;
    box-shadow: 0 2px 8px rgba(0,0,0,0.2);
    display: flex;
    flex-flow: row wrap;
    left: 0;
    padding: 5px 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 3;

    span.error-desc {
      color: unset;
      display: flex;
      flex-flow: row nowrap;
      flex: 1 0 50%;
      padding: 5px;
      width: 50%;

      p {
        color: #DC3545;
        flex: 1;
        margin: auto;
      }

      .icon {
        color: #DC3545;
        flex: 0 0 24px;
        margin: auto 6px auto 0;
        width: 24px;
      }

      &.passed {
        p {
          color: #3CC78E;
        }

        .icon {
          color: #3CC78E;
        }
      }
    }
  }
}
