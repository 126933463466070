@use "sass:math"; @import "@client/scss/__config.scss"; @import "@theme/scss/_config.scss"; @import "@core/plugins/scss/_mixins.scss";
.cell {
  max-height: 116px;
  overflow: hidden;
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
}
.expand-collapse {
  color: #007bff;
  cursor: pointer;
  display: block;
  margin-top: 8px;
}
