@use "sass:math"; @import "@cms/scss/_config.scss"; @import "@theme/scss/_config.scss"; @import "@core/plugins/scss/_mixins.scss";
div.dg-container .dg-main-content {
  .duplicate-page-modal {
    p {
      position: relative;

      label {
        line-height: 1.7;
        padding-left: 25px;
      }
    }

    fieldset {
      margin: 28px 0 18px;

      p.error {
        color: $red;
        font-size: 12px;
      }
    }

    footer {
      display: flex;
      flex-flow: row nowrap;

      button {
        flex: 0 0 auto;
        margin-left: 16px;
        min-width: 128px;

        &:first-of-type {
          margin-left: 0;
        }
      }
    }
  }
}
