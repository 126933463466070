@use "sass:math"; @import "@client/scss/__config.scss"; @import "@theme/scss/_config.scss"; @import "@core/plugins/scss/_mixins.scss";
form .error-box {
  background-color: red;
  color: #fff;
  padding: 7px 12px;
  margin-bottom: 15px;

  p {
    font-size: 14px;
    margin: 0;
    padding: 0;
  }
}
